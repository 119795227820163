<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="per-page-wrapper">
        <div>Show:</div>
        <div style="width: 1px; background: #D7D4D3"></div>
        <div>
          <b-dropdown size="sm" variant="link" right no-flip>
            <template v-slot:button-content>
              <div style="margin-left: -15px">{{ perPage }}</div>
            </template>

            <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
            <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
            <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
            <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        fixed-header
        hide-default-footer
        dense
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ data.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.name_program`]="{ item }">
          <div class="c-max-lines-2">
            {{ item.name_program }}
          </div>
        </template>

        <template v-slot:[`item.description_program`]="{ item }">
          <div class="c-max-lines-2">
            {{ strippedContent(item.description_program) }}
          </div>
        </template>

        <template v-slot:[`item.unit_management`]="{ item }">
          <div class="c-max-lines-2">
            {{ strippedContent(item.unit_management) }}
          </div>
        </template>

        <template v-slot:[`item.arrLevel_program`]="{ item }">
          <div class="c-max-lines-2">
            {{ Array.from(item.arrLevel_program, el => el.name).join("; ") }}
          </div>
        </template>

        <template v-slot:[`item.program_pj`]="{ item }">
          <div class="c-max-lines-2">
            {{ item.program_pj }}
          </div>
        </template>

        <template v-slot:[`item.duration`]="{ item }">
          <div class="c-max-lines-2">
            {{ item.from_date }} - {{ item.to_date }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              @click="
                selectedData = item;
                programDialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                getDetailData(item.id_program);
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id_program)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="dialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card :loading="detailLoading" elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title mb-8">
                {{
                  selectedData.id_program ? "Edit Program" : "Tambah Program"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group>
                  <div class="input-title">Nama Program</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name_program.$model"
                    :state="validateState('name_program')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Nama Program is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor
                    v-if="dialog"
                    v-model="form.description_program"
                  ></Editor>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Unit Manajemen</div>
                  <Editor v-if="dialog" v-model="form.unit_management"></Editor>
                </b-form-group>

                <div class="input-title">Komponen Kerja</div>
                <template v-for="(item, index) in form.arrKomponen_kerja">
                  <div
                    v-if="item.id >= 0"
                    :key="'komponen_' + index"
                    class="d-flex align-items-center"
                  >
                    <div
                      class="p-2 mb-4 flex-grow-1"
                      style="border: 1px solid #D7D4D3; border-radius: 8px"
                    >
                      <b-form-group class="mb-0">
                        <b-form-input
                          placeholder="Nama Komponen"
                          class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                          v-model="item.name"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          Nama Komponen is required.
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <v-color-picker
                        mode="hex"
                        hide-inputs
                        hide-canvas
                        @input="el => (item.color = el.hex)"
                      ></v-color-picker>

                      <Editor
                        v-if="dialog"
                        v-model="item.description"
                        placeholder="Deskripsi"
                      ></Editor>
                    </div>

                    <div>
                      <div
                        v-if="
                          form.arrKomponen_kerja.filter(el => el.id >= 0)
                            .length > 1
                        "
                        @click="deleteKomponenKerja(index)"
                        class="c-delete-btn"
                        v-ripple
                      >
                        <img
                          :src="`${baseAppPath}/media/delete-2-icon.svg`"
                          width="13px"
                        />
                      </div>

                      <!-- <div @click="onDelete()" class="c-delete-btn" v-ripple>
                        <img :src="`${baseAppPath}/media/add-icon.svg`" width="16px">
                      </div> -->
                    </div>
                  </div>
                </template>

                <button
                  @click="tambahKomponen"
                  class="btn btn-secondary d-block font-weight-bolder px-2 py-2 mt-2 mb-4"
                  style="border-radius: 10px; font-size: 10px"
                  v-ripple
                >
                  + Tambah Komponen Kerja
                </button>

                <div class="input-title">Level</div>
                <vue-select
                  v-model="form.arrLevel_program"
                  multiple
                  :options="arrLevel"
                  label="name"
                  placeholder="Select Level"
                  class="c-select-input"
                  style="min-width: 200px; margin-bottom: 15px"
                >
                </vue-select>

                <b-form-group>
                  <div class="input-title">Penanggung Jawab</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.program_pj.$model"
                    :state="validateState('program_pj')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Penanggung Jawab is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Durasi</div>
                  <div class="d-flex align-items-center">
                    <date-picker
                      v-model="form.from_date"
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      placeholder="YYYY-MM-DD"
                    ></date-picker>
                    <div class="mx-3 font-weight-bolder">to</div>
                    <date-picker
                      v-model="form.to_date"
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      placeholder="YYYY-MM-DD"
                    ></date-picker>
                  </div>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Durasi is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Logo</div>

                  <b-form-file
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="$v.form.photo.$model"
                    @change="
                      {
                        onFileChange;
                      }
                    "
                    :state="validateState('photo')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>

                  <div
                    v-if="form.photo"
                    :style="{
                      'background-image':
                        typeof form.photo == 'object'
                          ? `url(${localFile(form.photo)})`
                          : `url('${form.photo}')`
                    }"
                    class="w-100 overflow-hidden mt-2 position-relative"
                    style="height: 150px; background-size: contain; background-position: center center; border: 1px solid #E5EAEE; border-radius: 8px"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>

                    <button
                      @click="
                        form.photo = null;
                        logoTemp = null;
                      "
                      class="position-absolute btn btn-danger d-block font-weight-bolder px-2 py-1"
                      style="border-radius: 100px; top: 10px; right: 10px; font-size: 10px"
                      v-ripple
                    >
                      Hapus Gambar
                    </button>
                  </div>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Contact Person is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="programDialog"
      class="overflow-hidden"
      :style="{ height: programDialog ? 'inherit' : '0px' }"
    >
      <ProgramDialog
        :id="selectedData.id_program"
        :item="selectedData"
        :show="programDialog"
        :closeDialog="() => (programDialog = false)"
      />
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}

.c-delete-btn {
  margin-left: 4px;
  padding: 8px 11px;
  border-radius: 10px;
  cursor: pointer;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import Editor from "@/view/components/Editor/Editor.vue";
import ProgramDialog from "./ProgramDialog.vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const komponenKerja = {
  id: 0,
  name: null,
  type: "Komponen Kerja",
  color: "#06B56F",
  description: null
};

const formInit = {
  name_program: null,
  description_program: null,
  unit_management: null,
  arrLevel_program: [],
  from_date: null,
  to_date: null,
  program_pj: null,
  arrKomponen_kerja: [
    {
      ...komponenKerja
    }
  ],
  photo: null
};

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    Editor,
    DatePicker,
    ProgramDialog
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "50px" },
        { text: "Nama Program", value: "name_program", width: "200px" },
        { text: "Deskripsi", value: "description_program", width: "120px" },
        { text: "Unit Manajemen", value: "unit_management", width: "150px" },
        // { text: 'Komponen Kerja', value: 'komponen', width: '150px' },
        { text: "Level", value: "arrLevel_program", width: "200px" },
        { text: "Penanggung Jawab", value: "program_pj", width: "200px" },
        { text: "Durasi", value: "duration", width: "180px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      selectedData: {
        id_program: null
      },

      levelOptions: [
        "Nasional",
        "Provinsi",
        "Kabupaten",
        "Kecamatan",
        "Desa",
        "Internasional"
      ],
      arrLevel: [],
      selectedLevelOption: null,

      form: {
        ...formInit
      },

      logoTemp: null,

      loading: true,
      detailLoading: false,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      programDialog: false
    };
  },
  validations: {
    form: {
      name_program: {
        required,
        minLength: minLength(1)
      },
      description_program: {
        required,
        minLength: minLength(1)
      },
      unit_management: {
        required,
        minLength: minLength(1)
      },
      arrLevel_program: {
        required
      },
      from_date: {
        required,
        minLength: minLength(1)
      },
      to_date: {
        required,
        minLength: minLength(1)
      },
      program_pj: {
        required,
        minLength: minLength(1)
      },
      arrKomponen_kerja: {
        required
      },
      photo: {}
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData() {
      ApiService.get(
        `api/program/get?token=${JwtService.getToken(
          "program"
        )}&page=1&limit=9999`,
        "program"
      )
        .then(({ data }) => {
          if (data?.response?.arrProgram) {
            this.data = data?.response?.arrProgram;
          }
          if (data?.response?.arrLevel) {
            this.arrLevel = data?.response?.arrLevel;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDetailData(id) {
      this.detailLoading = true;

      ApiService.get(
        `api/program/get_detail?token=${JwtService.getToken(
          "program"
        )}&id_program=${id}`,
        "program"
      )
        .then(({ data }) => {
          if (data?.response?.arrKomponen_kerja) {
            this.form.arrKomponen_kerja = data?.response?.arrKomponen_kerja;
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    tambahKomponen() {
      this.form.arrKomponen_kerja = [
        ...this.form.arrKomponen_kerja,
        { ...komponenKerja }
      ];
    },
    deleteKomponenKerja(index) {
      let _arrKomponenKerja = this.form.arrKomponen_kerja;
      if (_arrKomponenKerja[index]?.id == 0) {
        _arrKomponenKerja.splice(index, 1);
      } else {
        _arrKomponenKerja[index].id = -_arrKomponenKerja[index].id;
      }

      this.form.arrKomponen_kerja = _arrKomponenKerja;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("token", JwtService.getToken("program"));
      formData.append("name_program", this.form.name_program);
      formData.append("description_program", this.form.description_program);
      formData.append("unit_management", this.form.unit_management);
      formData.append("from_date", this.form.from_date);
      formData.append("to_date", this.form.to_date);
      formData.append("pj_program", this.form.program_pj);
      formData.append("arrKomponen_kerja", this.form.arrKomponen_kerja);

      if (this.form.photo && typeof this.form.photo !== "string") {
        formData.append("photo", this.form.photo);
      }

      for (let i = 0; i < this.form.arrLevel_program.length; i++) {
        formData.append(
          `arrLevel_program[${i}]`,
          this.form.arrLevel_program[i].id
        );
      }

      for (let i = 0; i < this.form.arrKomponen_kerja.length; i++) {
        formData.append(
          `arrKomponen_kerja[${i}][id]`,
          this.form.arrKomponen_kerja[i].id
        );
        formData.append(
          `arrKomponen_kerja[${i}][name]`,
          this.form.arrKomponen_kerja[i].name
        );
        formData.append(
          `arrKomponen_kerja[${i}][description]`,
          this.form.arrKomponen_kerja[i].description
        );
        formData.append(
          `arrKomponen_kerja[${i}][color]`,
          this.form.arrKomponen_kerja[i].color
        );
      }

      if (!this?.selectedData?.id_program) {
        // Create
        ApiService.post("api/program/post", formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Program berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        formData.append("_method", "PATCH");
        formData.append("id_program", this?.selectedData?.id_program);

        ApiService.post(`api/program/update`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Program berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus program?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/program/delete?token=${JwtService.getToken(
                  "program"
                )}&id_program=${id}`,
                "program"
              )
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        ...formInit
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "").replace(/&nbsp;/g, " ");
    },
    localFile(file) {
      return URL.createObjectURL(file);
    }
  }
};
</script>
