<template>
  <v-dialog v-model="show" width="720px" height="80vh" persistent>
    <div class="c-dialog">
      <v-card :loading="loading">
        <v-card-text class="relative pb-10 px-10 pt-20">
          <div class="dialog-close" v-on:click="closeDialog" v-ripple>
            <img
              :src="`${baseAppPath}/media/close-icon.png`"
              width="14px"
              height="14px"
            />
          </div>

          <b-row>
            <b-col cols="12" sm="2">
              <div
                :style="{ backgroundImage: `url('${data.photo}')` }"
                style="background-size: contain; background-position: top center;"
              >
                <b-aspect aspect="1:1" class="w-100"> </b-aspect>
              </div>
            </b-col>

            <b-col cols="12" sm="10">
              <div class="program-title">{{ data.name_program }}</div>
              <div class="program-pic">
                Penanggung Jawab: {{ data.program_pj }}
              </div>
              <div class="program-duration">
                <div>{{ data.from_date }} - {{ data.to_date }}</div>
              </div>
            </b-col>
          </b-row>

          <div class="program-desc" v-html="data.description_program"></div>

          <div class="d-flex align-items-center mb-2">
            <div
              v-for="item in data.arrLevel_program"
              :key="item.id"
              class="program-tag"
            >
              {{ item.name }}
            </div>
          </div>

          <b-row>
            <b-col cols="12" sm="6">
              <div
                class="program-pic font-weight-bolder text-primary mb-2 mt-6"
              >
                Unit Manajemen
              </div>
              <div class="program-unit" v-html="data.unit_management"></div>
            </b-col>

            <b-col cols="12" sm="6">
              <div
                class="program-pic font-weight-bolder text-primary mb-2 mt-6"
              >
                Komponen Kerja
              </div>
              <div
                v-for="item in komponenKerja"
                :key="'komponen-' + item.id"
                class="program-komponen"
                :style="{ 'border-color': item.color }"
              >
                <div class="program-komponen-title">{{ item.name }}</div>
                <div
                  class="program-komponen-desc"
                  v-html="item.description"
                ></div>
              </div>
            </b-col>
          </b-row>

          <router-link
            class="text-white"
            :to="`/informasi-program/detail/${id}`"
          >
            <button
              class="btn btn-secondary mx-auto d-block font-weight-bolder px-6 py-3 mt-5"
              style="border-radius: 10px"
              v-ripple
            >
              Lihat Detil Program
            </button>
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
* {
  font-family: "Aller";
  font-size: 14px;
}

.program-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #8c8c8c;
}
.program-pic {
  margin-bottom: 6px;
  font-style: normal;
  color: #a29f9d;
}
.program-duration {
  display: inline-block;
  padding: 4px 12px;
  color: #a29f9d;
  border: 1px solid #d7d4d3;
  border-radius: 40px;
}
.program-desc {
  margin: 10px 0;
}
.program-tag {
  margin-right: 10px;
  padding: 4px 16px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 40px;
  color: #1e9354;
  background-color: rgba(6, 181, 111, 0.22);
}
.program-unit {
  text-align: justify;
}
.program-komponen {
  margin-bottom: 10px;
  padding-left: 12px;
  border-left: 6px solid;

  .program-komponen-title {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .program-komponen-desc {
    margin-bottom: 4px;
    font-size: 13px;
    color: #a6a5a5;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  props: ["id", "item", "show", "closeDialog"],
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      data: this.item,
      komponenKerja: [],

      loading: true
    };
  },
  mounted() {
    ApiService.get(
      `api/program/get_detail?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.id}`,
      "program"
    )
      .then(({ data }) => {
        if (data?.response?.arrProgram) {
          this.data = data?.response?.arrProgram;
          this.komponenKerja = data?.response?.arrKomponen_kerja;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {},
  methods: {
    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
